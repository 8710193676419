import React from 'react';
import {
    Typography,
    Box,
    Container,
} from '@mui/material';

export default function About() {
    return (
        <Box
            sx={{
                py: 8,
                textAlign: 'center',
                backgroundColor: '#f0f4f8', // Changed Background Color
                borderRadius: 0, // Optional: Rounded corners for a softer look
                boxShadow: 1, // Optional: Subtle shadow for depth
            }}
        >
            <Container id="about">
                <Typography variant="h4" gutterBottom>
                    About Meanbunny
                </Typography>
                <Typography variant="body1" maxWidth="md" margin="auto">
                    Meanbunny is a seasoned technology development firm with two decades of experience in creating robust
                    applications across Android, iOS, and web platforms. Specializing in cloud technologies like AWS and Azure, we
                    excel in planning, managing, and building high-performing development teams.
                </Typography>
            </Container>
        </Box>
    );
}
