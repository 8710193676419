import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import MeanbunnyLogo from './MeanBunnyLogo.svg';
import MenuIcon from '@mui/icons-material/Menu';

export default function Navbar() {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navItems = [
        { label: 'About', href: '#about' },
        { label: 'Services', href: '#services' },
        { label: 'Projects', href: '#projects' },
        { label: 'Contact', href: '#contact' },
    ];

    return (
        <>
            {/* AppBar for Desktop and Mobile */}
            <AppBar position="sticky">
                <Toolbar sx={{ justifyContent: 'space-between', px: 2 }}>
                    {/* Logo */}
                    <Box display="flex" alignItems="center">
                        <img src={MeanbunnyLogo} alt="Logo" height={40} />
                        <Typography variant="h6" sx={{ ml: 2 }}>
                            Meanbunny
                        </Typography>
                    </Box>

                    {/* Desktop Navigation */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
                        {navItems.map((item, index) => (
                            <Button key={index} color="inherit" href={item.href}>
                                {item.label}
                            </Button>
                        ))}
                    </Box>

                    {/* Mobile Menu Button */}
                    <IconButton
                        color="inherit"
                        edge="start"
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {/* Drawer for Mobile Navigation */}
            <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                    '& .MuiDrawer-paper': { width: 250 },
                }}
            >
                <Box sx={{ textAlign: 'center', py: 2 }}>
                    <img src={MeanbunnyLogo} alt="Logo" height={40} />
                    <Typography variant="h6" sx={{ mt: 1 }}>
                        Meanbunny
                    </Typography>
                </Box>
                <List>
                    {navItems.map((item, index) => (
                        <ListItem button key={index} onClick={handleDrawerToggle}>
                            <ListItemText>
                                <Button color="inherit" href={item.href} sx={{ width: '100%', textAlign: 'left' }}>
                                    {item.label}
                                </Button>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};


