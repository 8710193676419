import React from 'react';
import {
    Typography,
    Box,
    Link,
} from '@mui/material';

export default function Footer() {
    return (
        <Box component="footer" sx={{ backgroundColor: '#2c3e50', color: '#bdc3c7', py: 3, textAlign: 'center' }}>
            <Typography>© 2024 Meanbunny, LLC. All Rights Reserved.</Typography>
            <Typography>
                <Link href="mailto:info@m-mb.com" color="inherit" underline="hover">
                    Email Us
                </Link>{' '}
                |{' '}
                <Link href="#" color="inherit" underline="hover">
                    Privacy Policy
                </Link>
            </Typography>
        </Box>
    );
}
