import React, { useState, useEffect } from "react";
import { Typography, Button, Box } from "@mui/material";

export default function Hero() {
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

  // Define the possible background styles
  const backgroundStyles = [
    {
      label: "Plain White",
      styles: { background: "white" },
    },
    {
      label: "Striped Background",
      styles: {
        background: "white",
        backgroundImage:
          "linear-gradient(45deg, rgba(0, 0, 0, 0.05) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 75%, transparent 75%, transparent)",
        backgroundSize: "20px 20px",
      },
    },
    {
      label: "Dotted Background",
      styles: {
        background: "white",
        backgroundImage:
          "radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px)",
        backgroundSize: "10px 10px",
      },
    },
    {
      label: "Subtle Stripes",
      styles: {
        background: "white",
        backgroundImage:
          "linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px)",
        backgroundSize: "10px 10px",
      },
    },
    {
      label: "Crosshatch Pattern",
      styles: {
        background: "white",
        backgroundImage:
          "linear-gradient(45deg, rgba(0, 0, 0, 0.05) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 75%, transparent 75%, transparent), linear-gradient(135deg, rgba(0, 0, 0, 0.05) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 75%, transparent 75%, transparent)",
        backgroundSize: "20px 20px",
      },
    },
    {
      label: "Hexagonal Grid",
      styles: {
        background: "white",
        backgroundImage:
          "radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px), radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px)",
        backgroundSize: "20px 20px",
        backgroundPosition: "0 0, 10px 10px",
      },
    },
  ];

  // Set the interval duration (e.g., 5 seconds)
  const intervalDuration = 5000; // 5000 milliseconds = 5 seconds

  useEffect(() => {
    // Function to update the background index
    const updateBackground = () => {
      setCurrentBackgroundIndex(
        (prevIndex) => (prevIndex + 1) % backgroundStyles.length,
      );
    };

    // Start the interval
    const intervalId = setInterval(updateBackground, intervalDuration);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [backgroundStyles.length]);

  return (
    <Box
      id="hero"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        height: "100vh",
      }}
    >
      {/* Left Side: Image */}
      <Box
        sx={{
          flex: 1,
          background: `url('/hero.png') no-repeat center center`,
          backgroundSize: "cover",
        }}
      />

      {/* Right Side: Text with Dynamic Background */}
      <Box
        sx={{
          flex: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          px: 3,
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        {/* Background Layers for Smooth Transition */}
        {backgroundStyles.map((bg, index) => (
          <Box
            key={index}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transition: "opacity 1s ease-in-out",
              opacity: index === currentBackgroundIndex ? 1 : 0,
              zIndex: -1,
              ...bg.styles,
            }}
          />
        ))}

        {/* Content */}
        <Typography variant="h3" gutterBottom>
          Empowering Businesses with Scalable Tech Solutions
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          With 20 years of experience, Meanbunny specializes in custom software,
          cloud architecture, and expert team management to ensure your success.
        </Typography>
        <Box display="flex" gap={2}>
          <Button variant="contained" color="primary" href="#contact">
            Get a Free Consultation
          </Button>
          <Button variant="outlined" color="secondary" href="#projects">
            See Our Projects
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
