import React from 'react';

import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Projects from './components/Projects';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <React.Fragment>
      <Navbar />
      <Hero />
      <About />      
      <Services />     
      <Projects />
      <Contact />
      <Footer />
    </React.Fragment>
  );
}

export default App;

