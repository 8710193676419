// Projects.jsx

import React, { useState } from 'react';
import {
    Typography,
    Button,
    Box,
    Grid,
    Container,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'; // Import vertical icons

// Custom Screenshot Carousel Component with Improved Vertical Scrolling
const ScreenshotCarousel = React.memo(({ screenshots }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalSlides = screenshots.length;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Handler to go to the previous slide (up)
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalSlides - 1 : prevIndex - 1));
    };

    // Handler to go to the next slide (down)
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === totalSlides - 1 ? 0 : prevIndex + 1));
    };

    // Handler to jump to a specific slide
    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
            }}
        >
            {/* Previous Button (Up) */}
            <IconButton
                onClick={handlePrev}
                sx={{
                    position: 'absolute',
                    top: 16,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                    zIndex: 1,
                }}
                aria-label="Previous Slide"
            >
                <ArrowUpward />
            </IconButton>

            {/* Current Slide */}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'auto',
                    flexGrow: 1,
                }}
            >
                <img
                    src={screenshots[currentIndex]}
                    alt={`Screenshot ${currentIndex + 1}`}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        borderRadius: '8px',
                    }}
                    loading="lazy"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/projects/fallback.png'; // Replace with your fallback image path
                    }}
                />
            </Box>

            {/* Next Button (Down) */}
            <IconButton
                onClick={handleNext}
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                    zIndex: 1,
                }}
                aria-label="Next Slide"
            >
                <ArrowDownward />
            </IconButton>

            {/* Navigation Dots */}
            <Box
                sx={{
                    position: 'absolute',
                    right: 16,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                }}
            >
                {screenshots.map((_, index) => (
                    <IconButton
                        key={index}
                        onClick={() => handleDotClick(index)}
                        size="small"
                        sx={{
                            color: index === currentIndex ? 'primary.main' : 'grey.500',
                        }}
                        aria-label={`Go to slide ${index + 1}`}
                    >
                        <FiberManualRecord fontSize="small" />
                    </IconButton>
                ))}
            </Box>
        </Box>
    );

});

export default function Projects() {
    // Define the projects data with screenshots
    const projects = [
        {
            title: 'Wellspring Group',
            description:
                'Developed a book ordering platform that integrates with the print company LuLu, allowing the organization to automate the registration, payment, and book ordering for the courses they offer.',
            link: 'https://events.wellspringgroup.org',
            screenshots: [
                '/projects/wellspring/screenshot1.png',
                '/projects/wellspring/screenshot2.png',
                '/projects/wellspring/screenshot3.png',
            ],
        },
        {
            title: 'Android GPS + Interactive Web Map + Sweepstakes Activations',
            description:
                'An Android application that allows physical location-based activations to be tracked for an online web experience. Branded data-entry form that allows users to input data form fields to capture user information for entering a sweepstakes. Exportable sign-ups sheets available for picking sweepstakes winners.',
            link: '',
            screenshots: [
                '/projects/android-gps/screenshot1.png',
            ],
        },
        {
            title: 'User Provisioning',
            description:
                'A C# .NET application that integrates with Active Directory and ADP Payroll to create, activate, deactivate, and delete users in an Enterprise Active Directory system.',
            link: '',
            screenshots: [
            ],
        },
        {
            title: 'AI Video Library',
            description:
                'AI Video Library Tool that utilizes AI to transcribe, categorize, and identify objects, people, and landmarks in a searchable database.',
            link: '',
            screenshots: [
            ],
        },
    ];

    const [open, setOpen] = useState(false);
    const [selectedScreenshots, setSelectedScreenshots] = useState([]);

    // Handle opening the modal
    const handleOpen = (screenshots) => {
        console.log('Opening modal with screenshots:', screenshots);
        setSelectedScreenshots(screenshots);
        setOpen(true);
    };

    // Handle closing the modal
    const handleClose = () => {
        console.log('Closing modal');
        setOpen(false);
        setSelectedScreenshots([]);
    };

    return (
        <React.Fragment>
            <Container id="projects" sx={{ py: 8 }}>
                <Typography variant="h4" textAlign="center" gutterBottom>
                    Our Projects
                </Typography>
                <Grid container spacing={4}>
                    {projects.map((project, idx) => (
                        <Grid item xs={12} sm={6} md={4} key={idx}>
                            <Card
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    boxShadow: 3,
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6">{project.title}</Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                        {project.description}
                                    </Typography>
                                </CardContent>
                                <Box sx={{ p: 2, display: 'flex', gap: 1 }}>
                                    {project.link && (
                                        <Button variant="outlined" color="primary" href={project.link} target="_blank">
                                            Learn More
                                        </Button>
                                    )}
                                    {project.screenshots && project.screenshots.length > 0 && (
                                        <Button variant="contained" color="secondary" onClick={() => handleOpen(project.screenshots)}>
                                            View Screenshots
                                        </Button>
                                    )}
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {/* Screenshot Modal */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
                scroll="paper" // Allows the DialogContent to scroll if content overflows
                aria-labelledby="screenshot-dialog-title"
            >
                <DialogTitle id="screenshot-dialog-title">Screenshots</DialogTitle>
                <DialogContent dividers sx={{ padding: 2, height: '80vh', display: 'flex', flexDirection: 'column' }}>
                    {selectedScreenshots.length > 0 ? (
                        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                            <ScreenshotCarousel screenshots={selectedScreenshots} />
                        </Box>
                    ) : (
                        <Typography>No screenshots available.</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

