import React from 'react';
import { Container, Typography, Button } from '@mui/material';

export default function Contact() {
    return (
        <Container id="contact" sx={{ py: 8, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
                Contact Us
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Ready to take your project to the next level? Get in touch with us today!
            </Typography>
            <Button variant="contained" href="mailto:info@m-mb.com" color="primary">
                Get in Touch
            </Button>
        </Container>
    );
}
