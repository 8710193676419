import React from 'react';
import { 
    Typography, 
    Grid, 
    Container, 
    Card, 
    CardMedia, 
    CardContent, 
} from '@mui/material';
import CICD from './cicd.png';
import TeamPlanningManagement from './teamplanning-management.png';
import CloudDevelopment from './cloud-development.png';
import MobileWeb from './mobileweb.png';


export default function Services() {
    return (
<Container id="services" sx={{ py: 8 }}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Our Services
        </Typography>
        <Grid container spacing={4}>
          {[
            {
              title: 'Cloud Development',
              img: CloudDevelopment,
              description:
                'Leverage the power of AWS and Azure to build scalable, secure, and efficient cloud-based applications tailored to your business needs.',
            },
            {
              title: 'Mobile & Web Applications',
              img: MobileWeb,
              description:
                'Developing high-quality Android and iOS applications as well as responsive web apps using ReactJS and Node.js to ensure seamless user experiences.',
            },
            {
              title: 'Team Planning & Management',
              img: TeamPlanningManagement,
              description:
                'Expert planning and management of development teams to drive projects forward, ensuring timely and successful delivery.',
            },
            {
              title: 'CI/CD Architecture',
              img: CICD,
              description:
                'Integrate continuous integration and deployment pipelines to enhance your development workflow, ensuring rapid and reliable releases.',
            },
          ].map((service, idx) => (
            <Grid item xs={12} sm={6} md={3} key={idx}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  boxShadow: 3,
                }}
              >
                <CardMedia
                  component="img"
                  image={service.img}
                  alt={service.title}
                  sx={{
                    height: 160, // Increased height to give more space for the image
                    objectFit: 'contain', // Ensures the full image is displayed without cropping
                    backgroundColor: '#f5f5f5', // Optional: Light background for better visibility
                    padding: 1, // Adds spacing around the image
                  }}
                />
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Typography variant="h6">{service.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

    );
}
